import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ChevronLeftIcon sx={{ color: "white", fontSize: "35px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ChevronRightIcon sx={{ color: "white", fontSize: "35px" }} />
    </div>
  );
};

const ImageSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data = [
    {
      id: 1,
      img: "https://swarnsutra.com/api/product/banner1.jpg",
      title: "Cotton Saree",
      link: "/products/Cotton Saree",
    },
    {
      id: 2,
      img: "https://swarnsutra.com/api/product/Banner_2.jpg",
      title: "Cotton Saree",
      link: "/products/Cotton Saree",
    },
    {
      id: 3,
      img: "https://swarnsutra.com/api/product/Banner_3.jpg",
      title: "Banarasi Saree",
      link: "/products/Banarasi Saree",
    },
    {
      id: 4,
      img: "https://swarnsutra.com/api/product/Banner_4.jpg",
      title: "Pure Handloome Stole",
      link: "/products/Pashmina And Merino Stole",
    },
  ];
  return (
    <Slider
      {...settings}
      autoplay
      autoplaySpeed={2000}
      prevArrow={<PreviousBtn />}
      nextArrow={<NextBtn />}
    >
      {data.map((item) => {
        return (
          <div key={item.id} style={{ margin: "0 1rem" }}>
            <Link to={item.link}>
              <img
                src={item.img}
                alt={item.title}
                title={item.title}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Link>
          </div>
        );
      })}
    </Slider>
  );
};

export default ImageSlider;
