import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addToCartAsync,
  selectCartItems,
  updateCartItemAsync,
} from "../store/slices/CartSlice";
import { FormatPrice } from "../utils/FormatPrice";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";

const CategoryProducts = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const cartItems = useSelector(selectCartItems);
  let filterdata = useSelector((state) => state.filters.items);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const categoryProd = filterdata
    .filter((item) => item.category === params.category)
    ?.sort((a, b) => {
      if (a.quantity == 0 && b.quantity !== 0) {
        return 1;
      } else if (a.quantity !== 0 && b.quantity == 0) {
        return -1;
      } else {
        return 0;
      }
    });
  console.log(categoryProd);
  const selectPageHandler = (selectedPage) => {
    window.scrollTo(0, 0);
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(filterdata.length / 9) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const handleCart = (data) => {
    if (localStorage.isLogin == "true") {
      const itemIndex = cartItems.findIndex(
        (item) => item.product._id === data._id
      );
      if (itemIndex >= 0) {
        if (cartItems[itemIndex].cartQuantity >= data.quantity) {
          toast.warning("max quantity reached");
        } else {
          var newQuantity = cartItems[itemIndex].cartQuantity;
          newQuantity += 1;
          dispatch(
            updateCartItemAsync({
              id: cartItems[itemIndex]._id,
              quantity: newQuantity,
            })
          );
          toast.success(`${data.title} quantity increased`);
        }
      } else {
        dispatch(
          addToCartAsync({
            user: userInfo.userId,
            product: data._id,
            cartQuantity: 1,
          })
        );
        toast.success(`${data.title} added to cart`);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <Grid container spacing={2} mt={2} mb={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography
            color="green"
            fontFamily="Apple Symbols, Open Sans, Helvetica Neue"
            textTransform="uppercase"
          >
            {" "}
            <strong>category</strong> : {params.category}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography
            color="green"
            sx={{ textAlign: { xs: "", sm: "end" } }}
            fontFamily="Apple Symbols, Open Sans, Helvetica Neue"
            textTransform="uppercase"
          >
            <strong>No of Products</strong> : {categoryProd.length}
          </Typography>
        </Grid>

        {categoryProd.length == 0 ? (
          <Box pl={2}>
            <Typography letterSpacing={2} fontWeight={600} my={3}>
              Coming Soon...
            </Typography>
          </Box>
        ) : (
          categoryProd.slice(page * 9 - 9, page * 9).map((data) => (
            <Grid item xs={12} sm={6} md={4} key={data._id}>
              <Box p={2}>
                <Link to={`/singleproduct/${data._id}`}>
                  <Box
                    component="img"
                    src={data.images[0]}
                    sx={{
                      width: "100%",
                      height: { xs: "100%", md: "24rem", lg: "30rem" },
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "0.375rem",
                    }}
                  />
                </Link>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  mt={2}
                >
                  <Typography
                    fontWeight={600}
                    fontFamily="Apple Symbols, Open Sans, Helvetica Neue"
                    textTransform="uppercase"
                  >
                    {data.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontFamily="Apple Symbols, Open Sans, Helvetica Neue"
                    textTransform="uppercase"
                  >
                    {"("}
                    {data.fabric}
                    {")"}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="#999"
                  fontFamily="Apple Symbols, Open Sans, Helvetica Neue"
                  textAlign="left"
                  my={1}
                >
                  Price: {<FormatPrice price={data.price} />}/-
                </Typography>
                {data.quantity == 0 ? (
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ marginTop: "0.5rem" }}
                  >
                    Sold out
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      padding: "10px 16px",
                      backgroundColor: "#222222",
                      "&:hover": {
                        backgroundColor: "#f68872",
                      },
                    }}
                    onClick={() => handleCart(data)}
                    fullWidth
                  >
                    Add to Cart
                  </Button>
                )}
              </Box>
            </Grid>
          ))
        )}
        <Box
          width="100%"
          mt={2}
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{
              marginRight: "1.5rem",
              border: "1px solid #dee2e6",
              p: "0.5rem 0.75rem",
              color: "#333",
              display: `${page > 1 ? "" : "none"}`,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            startIcon={<ChevronLeftIcon />}
            onClick={() => selectPageHandler(page - 1)}
          >
            Previous
          </Button>
          {[...Array(Math.ceil(categoryProd.length / 9))].map((ele, i) => {
            return (
              <Box
                component="span"
                key={i}
                ml={0.5}
                mr={0.5}
                p="0.5rem 0.75rem"
                color={page === i + 1 ? "#fff" : "#000"}
                sx={{ cursor: "pointer" }}
                bgcolor={page === i + 1 ? "#333" : ""}
                border="1px solid #dee2e6"
                onClick={() => selectPageHandler(i + 1)}
              >
                {i + 1}
              </Box>
            );
          })}
          <Button
            sx={{
              marginLeft: "1.5rem",
              border: "1px solid #dee2e6",
              color: "#333",
              p: "0.5rem 0.75rem",
              display: `${
                page < Math.ceil(categoryProd.length / 10) ? "" : "none"
              }`,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            endIcon={<ChevronRightIcon />}
            onClick={() => selectPageHandler(page + 1)}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};

export default CategoryProducts;
