import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import {
  addToCartAsync,
  selectCartItems,
  updateCartItemAsync,
} from "../store/slices/CartSlice";
import { FormatPrice } from "../utils/FormatPrice";
import axios from "axios";
import Loading from "./Loading";
import Item from "../Components/Item";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { toast } from "react-toastify";
import {
  fetchRelatedProducts,
  selectRelatedProducts,
  selectmightLikeProducts,
} from "../store/slices/FilterSlice";
import FullScreenModal from "../Components/FullScreenModal";

const DownArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={className} onClick={onClick} style={{ ...style }}>
      <KeyboardArrowUpIcon
        sx={{
          color: "#333",
          fontSize: "35px",
          "&.hover": { backgroundColor: "inherit" },
        }}
      />
    </span>
  );
};

const UpArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={className} onClick={onClick} style={{ ...style }}>
      <KeyboardArrowDownIcon sx={{ color: "#333", fontSize: "35px" }} />
    </span>
  );
};
const SingleProduct = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [singleProduct, setSingleProduct] = useState([]);
  const [mainImage, setMainImage] = useState();
  const cartItems = useSelector(selectCartItems);
  const related = useSelector(selectRelatedProducts);
  const prodLike = useSelector(selectmightLikeProducts);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleCart = () => {
    if (localStorage.isLogin == "true") {
      const itemIndex = cartItems.findIndex(
        (item) => item.product._id === singleProduct[0]._id
      );
      if (itemIndex >= 0) {
        if (cartItems[itemIndex].cartQuantity >= singleProduct[0].quantity) {
          toast.warning("max quantity reached");
        } else {
          var newQuantity = cartItems[itemIndex].cartQuantity;
          newQuantity += 1;
          dispatch(
            updateCartItemAsync({
              id: cartItems[itemIndex]._id,
              quantity: newQuantity,
            })
          );
          toast.success(
            `${cartItems[itemIndex].product.title} quantity increased`
          );
        }
      } else {
        dispatch(
          addToCartAsync({
            user: userInfo.userId,
            product: singleProduct[0]._id,
            cartQuantity: 1,
          })
        );
        toast.success(`${singleProduct[0].title} added to cart`);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const fetchSingleProduct = async () => {
      const resp = await axios.post(
        "https://swarnsutra.com/api/singleProduct",
        { id: id }
      );
      setSingleProduct(resp.data);
      setMainImage(resp.data[0].images[0]);
      dispatch(
        fetchRelatedProducts({
          mainCat: resp.data[0].mainCat,
          category: resp.data[0].category,
        })
      );
    };
    fetchSingleProduct();
  }, [location.pathname]);

  if (singleProduct.length == 0 || mainImage == undefined) {
    return (
      <>
        <Loading />
      </>
    );
  }
  const {
    title,
    gender,
    category,
    rating,
    price,
    size,
    color,
    description,
    fabric,
    images,
    quantity,
    Sold,
    additionalInfo,
  } = singleProduct[0];

  const star = +rating;
  const ratingStar = Array.from({ length: 5 }, (elem, index) => {
    let number = index + 0.5;

    return (
      <span key={index}>
        {star >= index + 1 ? (
          <StarIcon sx={{ color: "#3fabd1", fontSize: "1.5rem" }} />
        ) : star >= number ? (
          <StarHalfIcon sx={{ color: "#3fabd1", fontSize: "1.5rem" }} />
        ) : (
          <StarOutlineIcon sx={{ color: "#3fabd1", fontSize: "1.5rem" }} />
        )}
      </span>
    );
  });

  var settings = {
    vertical: true,
    infinite: true,
    drag: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <UpArrow />,
    prevArrow: <DownArrow />,
  };

  return (
    <Container>
      <Typography
        variant="h5"
        textAlign="center"
        mt={2}
        mb={5}
        fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
        textTransform="uppercase"
        fontWeight={600}
        letterSpacing={3}
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
      >
        Product Details
      </Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={12} sm={6} md={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={3} md={3} lg={2} justifyContent="center">
              <div className="vertical-slider">
                <Slider {...settings}>
                  {images.map((curElm, index) => {
                    return (
                      <div key={index}>
                        <div
                          onClick={() => setMainImage(curElm)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={curElm}
                            alt={curElm.filename}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </Grid>
            <Grid
              item
              xs={8}
              sm={9}
              md={9}
              lg={8}
              position="relative"
              sx={{ pt: "48px !important" }}
            >
              <FullScreenModal images={images} />
              <Box
                component="img"
                src={mainImage}
                maxWidth="100%"
                width="100%"
                height="auto"
                sx={{ objectFit: "cover" }}
                bgcolor="#fafafa"
              ></Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant="h5"
            fontWeight={700}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
            textTransform="uppercase"
            sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
          >
            {title}
          </Typography>
          <small
            style={{
              textTransform: "uppercase",
              fontFamily: "Apple Symbols, Open Sans, Helvetica Neue",
            }}
          >
            {"("}
            {fabric}
            {")"}
          </small>

          <Typography
            mb={2}
            sx={{ mt: { xs: 1, md: 2 }, mb: { xs: 1, md: 2 } }}
            variant="h6"
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            <FormatPrice price={price} />
          </Typography>
          <Typography
            sx={{
              mt: { xs: 1, md: 2 },
              mb: { xs: 1, md: 2 },
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
            }}
            variant="h6"
            fontWeight={600}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            Rating:
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {ratingStar}{" "}
            <Typography
              fontWeight={600}
              fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
            >
              {"("}
              {Sold} Sold{")"}
            </Typography>
          </Box>

          <Typography
            sx={{
              mt: { xs: 1, md: 2 },
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
            }}
            fontWeight={600}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
            variant="h6"
          >
            Description:
          </Typography>
          <Typography fontFamily="Arial" sx={{ mt: { xs: 1, md: 2 } }}>
            {description}
          </Typography>

          {quantity == 0 ? (
            <Button variant="outlined" color="error" sx={{ marginTop: "2rem" }}>
              Sold out
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#222222",
                mt: { xs: "1rem", md: "2rem" },
                "&:hover": {
                  backgroundColor: "#f68872",
                },
                height: "2.875rem",
                width: "100%",
              }}
              onClick={() => handleCart()}
            >
              Add to cart
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Additional Information */}
      <Box mt={4}>
        <Typography
          variant="h6"
          mb={2}
          bgcolor="#aedded"
          sx={{
            fontSize: { xs: "1rem", md: "1.5rem" },
            textAlign: { xs: "left", md: "center" },
            padding: { xs: "1rem 0 1rem 1rem", md: "1rem 0" },
          }}
          fontWeight={700}
          fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          textTransform="uppercase"
        >
          Additional Information
        </Typography>
        {category == "Banarasi Saree" || category == "Designer Saree" ? (
          <Typography
            variant="h6"
            mb={2}
            sx={{
              textAlign: { xs: "left", md: "center" },
              padding: { xs: "1rem 0 1rem 1rem", md: "1rem 0" },
            }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            Blouse worn by model is only for photoshoot. Actual unstitched
            blouse separate in Picture
          </Typography>
        ) : (
          ""
        )}

        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Size:{" "}
          </Typography>
          <Typography
            sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            {size}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Fabric:{" "}
          </Typography>
          <Typography
            sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            {fabric}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Color:{" "}
          </Typography>
          <Typography
            sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            {color}
          </Typography>
        </Box>
        {category !== "Designer Saree" && (
          <Box
            display="flex"
            alignContent="center"
            sx={{
              p: { xs: "15px 0", sm: 2 },
              borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
            }}
            color="#777777"
          >
            <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
              Generic:{" "}
            </Typography>
            <Typography
              sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
              fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
            >
              Pure Handloom
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Manufacturer:{" "}
          </Typography>
          <Typography
            sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            PC Enterprises
          </Typography>
        </Box>

        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Category:{" "}
          </Typography>
          <Typography
            sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            {gender}
          </Typography>
        </Box>
        {category === "Banarasi Saree" ? (
          <Box
            display="flex"
            alignContent="center"
            sx={{
              p: { xs: "15px 0", sm: 2 },
              borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
            }}
            color="#777777"
          >
            <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
              Net Quantity:{" "}
            </Typography>
            <Typography
              sx={{
                ml: { xs: "0.5rem", sm: "4rem" },
                width: { xs: "100%", sm: "70%" },
              }}
              fontWeight="bold"
              fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
            >
              Every saree is a one-of-a-kind work of art that is weaved in one
              piece using an original design
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            alignContent="center"
            sx={{
              p: { xs: "15px 0", sm: 2 },
              borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
            }}
            color="#777777"
          >
            <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
              Net Quantity:{" "}
            </Typography>
            <Typography
              sx={{
                ml: { xs: "0.5rem", sm: "4rem" },
                width: { xs: "100%", sm: "70%" },
              }}
              fontWeight="bold"
              fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
            >
              {quantity}
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Country of Origin:{" "}
          </Typography>
          <Typography
            sx={{ ml: { xs: "0.5rem", sm: "4rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          >
            India
          </Typography>
        </Box>

        <Box
          display="flex"
          alignContent="center"
          sx={{
            p: { xs: "15px 0", sm: 2 },
            borderBottom: { xs: "none", sm: "1px solid rgba(224,224,244,1)" },
          }}
          color="#777777"
        >
          <Typography sx={{ width: { sm: "25%" } }} fontWeight={600}>
            Additional Information:{" "}
          </Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: additionalInfo }}
            sx={{ ml: { xs: "0.5rem", sm: "6.5rem", md: "8.5rem" } }}
            fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          ></Box>
        </Box>
      </Box>

      {/* Realated Products */}
      <Box mt={2} pb={2}>
        <Typography
          variant="h6"
          mb={2}
          bgcolor="#aedded"
          sx={{
            fontSize: { xs: "1rem", md: "1.5rem" },
            textAlign: { xs: "left", md: "center" },
            padding: { xs: "1rem 0 1rem 1rem", md: "1rem 0" },
          }}
          fontWeight={700}
          fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          textTransform="uppercase"
        >
          Related Products
        </Typography>
        <Grid container spacing={2} mt={0}>
          {related &&
            related.map((item) => <Item key={item._id} item={item} />)}
        </Grid>
      </Box>

      {/* might like products */}
      <Box mt={2} pb={2}>
        <Typography
          variant="h6"
          mb={2}
          bgcolor="#aedded"
          sx={{
            fontSize: { xs: "1rem", md: "1.5rem" },
            textAlign: { xs: "left", md: "center" },
            padding: { xs: "1rem 0 1rem 1rem", md: "1rem 0" },
          }}
          fontWeight={700}
          fontFamily="Apple Symbols,Open Sans, Helvetica Neue"
          textTransform="uppercase"
        >
          Product you Might Like
        </Typography>
        <Grid container spacing={2} mt={0}>
          {prodLike &&
            prodLike.map((item) => <Item key={item._id} item={item} />)}
        </Grid>
      </Box>
    </Container>
  );
};

export default SingleProduct;
